import {useContext, useEffect, useState, useRef} from 'react'
import  "./navbar.css"
import {assets} from '../../assets/assets'
import {Link, useLocation,useNavigate} from 'react-router-dom';
import { StoreContext } from '../../context/StoreContext';
import CartPopup from '../CartPopup/CartPopup';


const Navbar =()=>{
    const location = useLocation();   
    const navigate = useNavigate();
    const {cartItems} = useContext(StoreContext);

    const [showMobileNavbar, setShowMobileNavbar]= useState<boolean>(false);
    const [shadowNavbar, setShadowNavbar]= useState<boolean>(false);
    const [showCartPopup, setShowCartPopup]= useState<boolean>(false);
    const [isShaking, setIsShaking] = useState(false);

    const [prevCart,setPrevCart] = useState<{[key: string]: number}>({});
    const [newArticle,setNewArticle] = useState<{[key: string]: number}>({});
    const timerRef = useRef<NodeJS.Timeout | null>(null); // Store timer reference

    const handleResize = () => {
        if (window.innerWidth > 200) {
            setShowMobileNavbar(false);
        }
    };

    const handleScroll = () => {
        if (window.scrollY > 5) {
            setShadowNavbar(true);
        }else{
            setShadowNavbar(false);
        }
    };
    useEffect(() => {

        window.addEventListener('resize', handleResize);
        window.addEventListener('scroll', handleScroll);

        handleResize();
        handleScroll();

        return () => {
            window.removeEventListener('resize', handleResize);
            window.removeEventListener('scroll', handleScroll);
        };

    }, []);

    useEffect(() => {
        if (Object.keys(cartItems).length > Object.keys(prevCart).length) {
            setIsShaking(true);
            const updatedArticle = Object.keys(cartItems).reduce<{ [key: string]: number }>((diff, key) => { 
                if (!(key in prevCart)) { // Only add new items, ignore quantity changes
                    diff[key] = cartItems[key]; 
                }
                return diff;
            }, {});
            console.log("newArticle: ",updatedArticle);
            setNewArticle(updatedArticle);
            setPrevCart(cartItems);

            // Clear existing timer before setting a new one
            if (timerRef.current) clearTimeout(timerRef.current);

            timerRef.current = setTimeout(() => {
                setNewArticle({});
                setShowCartPopup(false);
                timerRef.current = null; // Reset ref
            }, 3000);
            // Short shake animation
            const timer2 = setTimeout(() => setIsShaking(false), 500);
            return () => clearTimeout(timer2);
        }
        else if (Object.keys(cartItems).length < Object.keys(prevCart).length){
            setPrevCart(cartItems);
        }
      }, [cartItems]);

    return(
        <div className={`navbar ${(shadowNavbar && "navbar-shadow")}`}>
            <div className="navbar-contents">
                <Link to="/">
                    <img src={assets.logo} alt="Logo" className="logo" />
                </Link>
                <ul className="navbar-menu">
                    <Link to="/" className={location.pathname==="/"?"active":""}>Accueil</Link>
                    <Link to="/produits" className={location.pathname.includes("/produits")?"active":""}>Produits</Link>
                    <Link to="/planning" className={location.pathname==="/planning"?"active":""}>Planning</Link>
                </ul>
                <div className="navbar-right">                
                    <div className={`navbar-panier-icon ${isShaking ? "shake" : ""} ${location.pathname==="/panier"?"active":""}`}
                        onMouseEnter={() => {
                            if (window.innerWidth > 650) {
                                setShowCartPopup(true);
                                setNewArticle({});
                            }
                        }}
                        onMouseLeave={() => {
                            if (window.innerWidth > 650) {
                                setShowCartPopup(false);
                            }
                        }}
                        onClick={()=>{setShowCartPopup(false);navigate('/panier');}}>
                        <img src={assets.panier} alt="Panier button" className="panier"/>
                        {Object.keys(cartItems).length > 0 && (<div className="dot"></div>) }
                        <CartPopup newArticle={newArticle} show={showCartPopup} />
                    </div>
                    <img onClick={() => {setShowMobileNavbar(!showMobileNavbar)}} src={ showMobileNavbar ? assets.exit : assets.menu} alt="Menu button" className="menu" />

                    {/*<img src={assets.search} alt="Search button" className="search" />*/}
                    {/*<button>Connexion</button>*/}
                </div>
            </div>
            {showMobileNavbar && (<div className="navbar-mobile">
                <Link to="/" onClick={() => setShowMobileNavbar(false)} className={location.pathname==="/"?"active":""}>Accueil</Link>
                <Link to="/produits" onClick={() => setShowMobileNavbar(false)} className={location.pathname.includes("/produits")?"active":""}>Produits</Link>
                <Link to="/planning" onClick={() => setShowMobileNavbar(false)} className={location.pathname==="/planning"?"active":""}>Planning</Link>
            </div>)}
        </div>
    );

}


export default Navbar;
