import "./cartpopup.css";
import {useContext} from 'react'
import { StoreContext } from "../../context/StoreContext";
import { Link } from 'react-router-dom';

type argumentsCartPopup = {
    newArticle?:{[key: string]: number},
    show:boolean
}

const CartPopup = ({newArticle,show}:argumentsCartPopup) => {

    const {products,cartItems} = useContext(StoreContext);

    return(
        <div className={`card ${(show || (newArticle && Object.keys(newArticle).length>0)) ? "card-show":""}`}>
            { !newArticle || Object.keys(newArticle).length<=0 && 
                (
                    <div className="card-header">
                        <p className="card-header-alert">Mon Panier</p>
                        <p className="text-align:right;">({Object.keys(cartItems).length} articles)</p>
                    </div>
            )}         
            
            {/*<p className="card-message">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Ipsam ea quo unde
                vel adipisci blanditiis voluptates eum. Nam, cum minima?
            </p>*/}

            <div className="card-items">
                {newArticle && Object.keys(newArticle).length > 0 ? (
                    Object.keys(newArticle).map((key) => {
                        const product = products[key]; // Get product safely

                        return (
                            <div className="card-item" key={key}>
                                <p className="card-item-quantity">{cartItems[key]}</p>
                                {product ? (
                                    <>
                                        <img src={product.image} alt="Produit" className="card-item-image" />
                                        <p className="card-item-name">{product.name}</p>
                                    </>
                                ) : (
                                    <p className="card-item-name">Produit inconnu</p> // Fallback for missing products
                                )}
                            </div>
                        );
                    })
                ) : (Object.keys(cartItems).length>0) ? Object.keys(cartItems).map((key, index) => {
                    return (
                        <div className="card-item" key={index}>
                            <p className="card-item-quantity">{cartItems[key]}</p>
                            <img src={products[key]?.image} alt="Produit" className="card-item-image" />
                            <p className="card-item-name">{products[key]?.name}</p>
                        </div>
                    )
                }) :
                (
                <p>Pour le moment, votre panier est vide!</p>
                )}

            </div>

            { !newArticle || Object.keys(newArticle).length<=0 && 
                (<div className="card-actions">
                    <Link className="card-actions-1" to="/panier">Voir le panier</Link>
                    {/*<a className="card-actions-2" onClick={()=>{console.log("action 2")}}>
                        Fermer
                    </a>*/}
                </div>)}
        </div>

    )

}


export default CartPopup;